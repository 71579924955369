import Vue from 'vue';
import Vuex from 'vuex';
import common from '../js/common';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isHiworks: false,
    isVisible: false,
    isLoading: true,
    isLogin: false,
    type: '',
    payment_expiry_date: 0,
    temp_unused_price: 0,
    total_pricing: {},
    estimate_list: [],
    receiver_info: {
      date: '',
      pay_condition: '전액 선납',
      expire_date: '',
      total_price: 0,
      receiver: '',
      phone_number: '1544-4370',
    },
    virtual: null,
  },
  getters: {
  },
  mutations: {
    setHostHiworks(state, isHiworks) {
      state.isHiworks = isHiworks
    },
    setVisible(state, visible) {
      state.isVisible = visible
    },
    setLoading(state, loading) {
      state.isLoading = loading
    },
    setEstimateList(state, list) {
      state.type = list.type
      // estimate_list setting
      if (list.type === 'step1') {
        // 신청 1단계에서 넘어 오는 데이터 기준으로 견적서 데이터 생성
        state.payment_expiry_date = list.payment_expiry_date
        state.estimate_list = list.service_list
        state.estimate_list.forEach((ele) => {
          ele.domain = ele.domain.toLowerCase()
          if (ele.carve_code_str.includes('도메인')) {
            ele.carve_code_str = ele.carve_code_str.replace(/[0-9]/g, '')
          }
          ele.goods_lists.forEach((element) => {
            if (element.type === 'installation') {
              element.quantity = '-'
              element.goods_order_term_display = ['최초 1회']
            } else {
              const split_date = element.goods_order_term_display.split(' ')
              element.goods_order_term_display = split_date
            }
            if (element.pricing_rule_list === null) {
              element.pricing_rule_list = []
            }
            if (element.volume !== '' && element.volue_unit !== '') {
              element.volume *= element.quantity
            }
          })
        })

        // 미사용 금액이 존재하는 경우
        // 제디터 대응을 위함
        if (list && list.total_pricing && list.total_pricing.total_unused_price) {
          state.temp_unused_price = list.total_pricing.total_unused_price
        }
      } else if (list.type === 'goods') {
        // goods-api 기준으로 넘어 오는 데이터를 견적서 데이터 생성
        state.payment_expiry_date = list.payment_expiry_date
        const service_list = []
        let total_unused_price = 0
        list.goods_pricing_list.forEach((ele) => {
          const obj = {}
          const goods_list = []
          let goods_obj = {}
          let saveFlag = true
          obj.carve_code = ele.carve_code
          obj.carve_code_str = ele.carve_code_str
          obj.domain = ele.domain.toLowerCase()
          goods_obj.type = 'goods'
          goods_obj.goods_name = ele.goods_name
          // 껍데기 상품 표기 예외 처리
          if (ele.order_term_unit === 'own' && ele.sub_goods_pricing_list !== null) {
            saveFlag = false
          } else {
            goods_obj.unit_price = ele.price.unit_price
            goods_obj.quantity = ele.quantity
            goods_obj.origin_supply_price = ele.price.final_supply_price
            const split_date = ele.goods_order_term_detail.goods_order_term_display.split(' ')
            goods_obj.goods_order_term_display = split_date
          }
          if (saveFlag) {
            // 상품추가변경 시 미사용금액 관련 예외처리
            if ('change_goods_unused_price' in ele) {
              if (ele.change_goods_unused_price === null) {
                goods_obj.change_goods_unused_price = null
              } else {
                goods_obj.change_goods_unused_price = ele.change_goods_unused_price
                total_unused_price += ele.change_goods_unused_price.unused_price
                if (ele.change_goods_unused_price.unused_price !== 0) {
                  goods_obj.origin_supply_price = ele.price.final_supply_price + ele.change_goods_unused_price.unused_price
                }
              }
            } else {
              goods_obj.change_goods_unused_price = null
            }
            if (ele.price.pricing_rule_list == null) {
              goods_obj.pricing_rule_list = []
            } else {
              goods_obj.pricing_rule_list = ele.price.pricing_rule_list
              goods_obj.pricing_rule_list.forEach((e) => {
                if (e.display_value.includes('원')) {
                  e.display_value = ''
                }
              })
            }
            goods_list.push(goods_obj)
          }
          goods_obj = {}
          if (ele.installation_cost_price != null) {
            goods_obj.type = 'installation'
            goods_obj.goods_name = '설치비'
            goods_obj.unit_price = ele.installation_cost_price.unit_price
            goods_obj.quantity = '-'
            goods_obj.goods_order_term_display = ['최초 1회']
            goods_obj.origin_supply_price = ele.installation_cost_price.final_supply_price
            goods_obj.change_goods_unused_price = null
            if (ele.installation_cost_price.pricing_rule_list == null) {
              goods_obj.pricing_rule_list = []
            } else {
              goods_obj.pricing_rule_list = ele.installation_cost_price.pricing_rule_list
              goods_obj.pricing_rule_list.forEach((e) => {
                if (e.display_value.includes('원')) {
                  e.display_value = ''
                }
              })
            }
            goods_list.push(goods_obj)
            goods_obj = {}
          }
          if (ele.sub_goods_pricing_list != null) {
            ele.sub_goods_pricing_list.forEach((element) => {
              goods_obj.type = ele.order_term_unit === 'own' ? 'goods' : 'sub'
              goods_obj.goods_name = element.goods_name
              goods_obj.unit_price = element.price.unit_price
              goods_obj.quantity = element.quantity
              const split_date = element.goods_order_term_detail.goods_order_term_display.split(' ')
              goods_obj.goods_order_term_display = split_date
              goods_obj.origin_supply_price = element.price.final_supply_price
              goods_obj.change_goods_unused_price = null
              if (element.price.pricing_rule_list == null) {
                goods_obj.pricing_rule_list = []
              } else {
                goods_obj.pricing_rule_list = element.price.pricing_rule_list
                goods_obj.pricing_rule_list.forEach((e) => {
                  if (e.display_value.includes('원')) {
                    e.display_value = ''
                  }
                })
              }
              goods_list.push(goods_obj)
              goods_obj = {}
            });
          }
          obj.goods_lists = goods_list
          service_list.push(obj)
        })
        // 동일 서비스 체크
        const idMap = service_list.map((e) => {
          const temp = {
            carve_code: e.carve_code,
            carve_code_str: e.carve_code_str,
            domain: e.domain,
          }
          return temp
        })
        const idMerge = idMap.filter((item, i) => {
          return (
            idMap.findIndex((item2) => {
              return item.carve_code === item2.carve_code && item.domain === item2.domain
            }) === i
          )
        })
        idMerge.forEach((ele) => {
          const goods = []
          service_list.forEach((el) => {
            if (ele.carve_code === el.carve_code && ele.domain === el.domain) {
              goods.push(...el.goods_lists)
            }
          })
          ele.goods_lists = goods
        })
        state.temp_unused_price = total_unused_price
        state.estimate_list = idMerge
      } else if (list.type === 'order') {
        // 기간 데이터를 배열로 바꾸면서 기존에 견적서 로그 데이터에 쌓여있던 기간 문자열 데이터를 배열로 전환
        list.estimate_list.forEach((ele) => {
          ele.goods_lists.forEach((element) => {
            if (typeof element.goods_order_term_display === 'string') {
              const temp_date = element.goods_order_term_display
              element.goods_order_term_display = [temp_date]
            }
          })
        })
        state.type = list.type
        state.estimate_list = list.estimate_list
        state.receiver_info = list.receiver_info
        state.isHiworks = list.is_hiworks
        console.log(list.estimate_list)
      } else if (list.type === 'payment') {
        const service_list = []
        list.detail.forEach((ele) => {
          const obj = {}
          const goods_list = []
          let goods_obj = {}
          obj.carve_code = ele.carve_code
          obj.carve_code_str = ele.carve_name
          obj.domain = ele.domain.toLowerCase()
          goods_obj.type = 'goods'
          goods_obj.goods_name = ele.goods_name
          goods_obj.origin_supply_price = ele.extend_price_list[0].extend_price + ele.extend_price_list[0].cut_price
          goods_obj.vat_price = ele.extend_price_list[0].vat_price
          goods_obj.cut_price = ele.extend_price_list[0].cut_price
          goods_obj.extend_price = ele.extend_price_list[0].extend_price
          let period = 0
          if (['domain', 'maintain', 'freehome', 'sslhosting'].includes(ele.carve_code)) {
            period = parseInt(ele.extend_price_list[0].period / 12, 10)
            goods_obj.goods_order_term_display = [`${period}년`]
          } else {
            period = parseInt(ele.extend_price_list[0].period, 10)
            goods_obj.goods_order_term_display = [`${period}개월`]
          }
          goods_obj.quantity = 1
          goods_obj.unit_price = Math.floor(ele.extend_price_list[0].origin_price / period)
          goods_obj.pricing_rule_list = []
          if (ele.extend_price_list[0].discount_info !== null) {
            let rule_obj = {}
            ele.extend_price_list[0].discount_info.forEach((e) => {
              rule_obj.display_rule_name = e.name
              rule_obj.display_value = e.rate ? e.rate.toString() + '%' : ''
              rule_obj.supply_rule_price = e.price * -1
              goods_obj.pricing_rule_list.push(rule_obj)
              rule_obj = {}
            })
          }
          goods_list.push(goods_obj)
          goods_obj = {}
          if (ele.addition_goods != null && ele.addition_goods.length > 0) {
            ele.addition_goods.forEach((e) => {
              if (e.service_goods) {
                goods_obj.type = e.service_goods[0].goods_link_type === 'main' ? 'goods' : 'sub'
              } else {
                goods_obj.type = 'sub'
              }
              goods_obj.goods_name = e.goods_name
              goods_obj.origin_supply_price = e.extend_price_list[0].extend_price + e.extend_price_list[0].cut_price
              goods_obj.vat_price = e.extend_price_list[0].vat_price
              goods_obj.cut_price = e.extend_price_list[0].cut_price
              goods_obj.extend_price = e.extend_price_list[0].extend_price
              period = 0
              if (['domain', 'maintain', 'freehome', 'sslhosting'].includes(e.carve_code)) {
                period = parseInt(e.extend_price_list[0].period / 12, 10)
                goods_obj.goods_order_term_display = [`${period}년`]
              } else {
                period = parseInt(e.extend_price_list[0].period, 10)
                goods_obj.goods_order_term_display = [`${period}개월`]
              }
              goods_obj.quantity = 1
              goods_obj.unit_price = Math.floor(e.extend_price_list[0].origin_price / period)
              goods_obj.pricing_rule_list = []
              if (e.extend_price_list[0].discount_info !== null) {
                let rule_obj = {}
                e.extend_price_list[0].discount_info.forEach((li) => {
                  rule_obj.display_rule_name = li.name
                  rule_obj.display_value = li.rate ? li.rate.toString() + '%' : ''
                  rule_obj.supply_rule_price = li.price * -1
                  goods_obj.pricing_rule_list.push(rule_obj)
                  rule_obj = {}
                })
              }
              goods_list.push(goods_obj)
              goods_obj = {}
            })
          }
          obj.goods_lists = goods_list
          service_list.push(obj)
        })
        state.estimate_list = service_list
      }

      // total_pricing setting
      if (list.type !== 'payment') {
        state.total_pricing = list.total_pricing
      } else {
        let total_price = 0
        let cut_price = 0
        let vat = 0
        state.estimate_list.forEach((ele) => {
          const extend_p = ele.goods_lists.reduce((acc, cur) => {
            return acc + cur.extend_price
          }, 0)
          const vat_p = ele.goods_lists.reduce((acc, cur) => {
            return acc + cur.vat_price
          }, 0)
          const cut_p = ele.goods_lists.reduce((acc, cur) => {
            return acc + cur.cut_price
          }, 0)
          total_price += extend_p
          vat += vat_p
          cut_price += cut_p
        })
        state.total_pricing.total_price = total_price
        state.total_pricing.cut_price = cut_price
        state.total_pricing.vat = vat
        state.total_pricing.total_price_with_vat = total_price + vat
        state.total_pricing.total_rule_price = 0
      }

      // price set comma / receiver_info pay_condition set
      if (state.type !== 'order') {
        state.estimate_list.forEach((element) => {
          if (element.carve_code === 'cloud') {
            state.receiver_info.pay_condition = '전액 후납'
          } else {
            state.receiver_info.pay_condition = '전액 선납'
          }
          element.goods_lists.forEach((ele) => {
            ele.origin_supply_price = common.methods.changeNumberFormat(ele.origin_supply_price)
            ele.unit_price = common.methods.changeNumberFormat(ele.unit_price)
            if (ele.volume) {
              ele.volume = common.methods.changeNumberFormat(ele.volume)
            }
            ele.pricing_rule_list.forEach((item) => {
              item.supply_rule_price_display = common.methods.changeNumberFormat(item.supply_rule_price)
            })
          })
        })
        state.total_pricing.total_price_display = common.methods.changeNumberFormat(state.total_pricing.total_price)
        state.total_pricing.total_price_with_vat_display = common.methods.changeNumberFormat(state.total_pricing.total_price_with_vat)
        state.total_pricing.total_rule_price_display = common.methods.changeNumberFormat(state.total_pricing.total_rule_price)
        state.total_pricing.vat_display = common.methods.changeNumberFormat(state.total_pricing.vat)
        state.total_pricing.total_unused_price = state.temp_unused_price
        state.total_pricing.total_unused_price_display = common.methods.changeNumberFormat(state.total_pricing.total_unused_price)
        let temp_cut = 0
        if (state.total_pricing.cut_price < 0) {
          temp_cut = -1 * state.total_pricing.cut_price
        } else {
          temp_cut = state.total_pricing.cut_price
        }
        temp_cut = common.methods.changeNumberFormat(temp_cut)
        state.total_pricing.cut_price_display = temp_cut

        // receiver_info setting
        const d = new Date()
        const ko_date = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString()
        state.receiver_info.date = ko_date.substr(0, 10)
        state.receiver_info.expire_date = (list.payment_expiry_date === 0) ? '견적일로부터 당일' : '견적일로부터 ' + list.payment_expiry_date + '일'
        state.receiver_info.total_price = state.total_pricing.total_price_with_vat_display
        state.receiver_info.receiver = ''
        state.receiver_info.phone_number = (state.isHiworks) ? '1661-4370' : '1544-4370'
      }
      if (list.virtual) {
        state.virtual = list.virtual
        state.virtual.price_display = common.methods.changeNumberFormat(state.virtual.price)
      } else {
        state.virtual = null
      }
    },
    setEstimateSubList(state, { sub_list, index }) {
      state.estimate_list[index].sub_list = sub_list
    },
    setLogin(state, isLogin) {
      state.isLogin = isLogin
    },
  },
  actions: {
  },
  modules: {
  },
});
